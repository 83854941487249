<template>
  <div class="flex items-center justify-center h-screen bg-white">
    <div class="w-1/4">
      <ValidationObserver v-slot="{ invalid }">
        <form @submit.prevent="searchCorpse" class="">
          <h1 class="text-3xl font-bold text-black mb-10">
            Find Corpse Profile
          </h1>
          <ValidationProvider
            name="Tag number"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="mb-5">
              <label class="block mb-2">Tag No.</label>
              <input
                type="text"
                placeholder="Corpse Tag Number"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border-red-400 border' : ''"
                v-model="tag"
              />
            </div>
          </ValidationProvider>
          <button
            class="w-full mt-14 text-white py-3 rounded-md bg-opacity-80 transition-all hover:bg-opacity-100 disabled:cursor-not-allowed"
            :class="invalid ? 'bg-gray-300' : 'bg-primary'"
            :disabled="invalid && submitting"
          >
            {{ submitting ? "Please wait..." : "Search" }}
          </button>
        </form>
      </ValidationObserver>
    </div>
    <corpse-profile
      v-if="profileModal"
      :corpse="corpse"
      @close="profileModal = false"
      @proceed="proceed"
    />
    <corpse-release
      v-if="corpseReleaseModal"
      @close="corpseReleaseModal = false"
      @proceed="toBilling"
      :corpse_id="`${corpse.id}`"
    />
    <div
      class="fixed z-40 top-0 bottom-0 left-0 right-0 h-full w-full bg-black bg-opacity-50 flex justify-end"
      v-if="billingModal"
    >
      <div class="w-3/4 h-full"></div>
      <div class="w-1/4 h-full bg-white rounded-l-2xl p-10 overflow-auto">
        <div class="flex items-center justify-between">
          <h1 class="text-black font-bold text-xl">
            Release Authorization Form
          </h1>
          <div
            class="bg-red-500 bg-opacity-30 p-5 rounded-full cursor-pointer"
            @click="billingModal = false"
          >
            <CloseIcon />
          </div>
        </div>
        <billing-sheet-modal
          :corpse="billing"
          :name="corpse.contact_person"
          @created="proceedToInvoice"
        />
      </div>
    </div>
    <div
      v-if="invoiceModal"
      class="fixed z-40 top-0 bottom-0 left-0 right-0 h-full w-full bg-black bg-opacity-50 flex justify-end"
    >
      <div
        class="w-1/4 h-full cursor-pointer"
        @click="invoiceModal = false"
      ></div>
      <div class="w-3/4 h-full bg-white rounded-l-2xl p-10 overflow-auto">
        <div class="flex items-center justify-between">
          <h1 class="text-black font-bold text-xl">Invoice Generation</h1>
          <div
            class="bg-red-500 bg-opacity-30 p-5 rounded-full cursor-pointer"
            @click="invoiceModal = false"
          >
            <CloseIcon />
          </div>
        </div>
        <invoice :invoice="invoice" />
      </div>
    </div>
  </div>
</template>

<script>
import { getCorpse } from "@/services/addmission.js";

export default {
  name: "CorpseReleasePage",
  components: {
    CorpseProfile: () => import("@/components/Addmission/CorpseProfile.vue"),
    CorpseRelease: () =>
      import("@/components/Addmission/CorpseReleaseModal.vue"),
    BillingSheetModal: () =>
      import("@/components/Addmission/BillingSheetModal.vue"),
    Invoice: () => import("@/components/Addmission/Invoice.vue"),
    CloseIcon: () => import("@/assets/icons/CloseIcon.vue"),
  },
  data() {
    return {
      tag: "",
      corpse: {},
      billing: {},
      invoice: {},
      submitting: false,
      profileModal: false,
      corpseReleaseModal: false,
      billingModal: false,
      invoiceModal: false,
      corpse_id: "",
    };
  },
  methods: {
    async searchCorpse() {
      this.submitting = true;
      try {
        const response = await getCorpse(this.tag);
        if (response.data.corpse.name_of_deceased === "") {
          this.$toast.info("No corpse found, are you sure it was admitted?");
          return;
        }
        this.corpse_id = response.data.corpse.corpse_id;
        this.corpse = response.data.corpse;
        this.profileModal = true;
      } catch (error) {
        console.log(error);
        this.$toast.error(error.response.data.message || error);
      }
      this.submitting = false;
    },
    proceed(corpse_id) {
      console.log(corpse_id);
      this.profileModal = false;
      this.corpseReleaseModal = true;
    },
    toBilling() {
      this.corpseReleaseModal = false;
      this.billing = this.corpse;
      this.billingModal = true;
    },
    proceedToInvoice(invoice) {
      this.billingModal = false;
      this.invoice = invoice;
      this.invoiceModal = true;
    },
  },
};
</script>

<style></style>
